/* For making the remark box bigger */
.remark-box {
    height: 150px; /* Increase height for better user input */
    resize: vertical; /* Allow user to resize vertically */
  }
  
  /* Making modal responsive */
  @media (max-width: 768px) {
    .popup {
      width: 90%;
      margin: auto;
    }
  
    .modal-content {
      padding: 2%;
    }
  
    .form-group {
      margin-bottom: 1rem;
    }
  
    .modal-footer button {
      width: 100%;
      margin-top: 1rem;
    }
  }
  
  /* Make it even more responsive for very small screens */
  @media (max-width: 480px) {
    .modal-content {
      padding: 1rem;
    }
  
    .form-group {
      margin-bottom: 0.5rem;
    }
  
    .remark-box {
      height: 120px; /* Adjust remark box height for smaller screens */
    }
  }
  