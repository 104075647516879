.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7e7a7a8c;
    z-index: 5; /* Adjust the z-index as needed */
}

.popup {
    position: fixed;
    top: 30px;
    left: 0;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    margin-left: 30%;
    z-index: 5; /* Adjust the z-index as needed */
}

.popup__content {
    width: 80%;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 999; /* Adjust the z-index as needed */
    background-color: white;
    max-height: 90vh; /* Prevent modal from exceeding viewport */
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.modal-body {
    padding: 15px;
    max-height: 60vh; /* Set max height for scrolling */
    overflow-y: auto; /* Enables scrolling inside the modal */
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.form-control {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

select.form-control {
    cursor: pointer;
}

.btn {
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

.btn-danger {
    background-color: #dc3545;
    color: white;
}

.btn-primary {
    background-color: #000;
    color: white;
}
