.modal-content {
    width: 75%;
    height: auto;
    padding: 2%;
    background-color: #fff;
    border-radius: 5px;
  }
  .modal-header {
    width: 100%;
    margin-bottom: 3%;
    border-bottom: solid 1px #7e7a7a8c;
  }
  