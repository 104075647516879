/* chat */
.my-chat {
    margin-left: 10%;
    align-items: right;
    padding: 4px;
    color: black;
    display: grid;
    justify-items: right;
  }
  
  .other-chat {
    margin-right: 10%;
    align-items: left;
    padding: 4px;
    display: grid;
    justify-items: left;
    /* word-wrap: break-word; */
  }
  
  .chat-container {
    /* height: 100%; */
    height: calc(100% - 5% - 15%); 
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin; 
  }
  .chat-container::-webkit-scrollbar {
    width: 0.2px; 
  }
  .chat-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 1px;
  }