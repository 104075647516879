.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7e7a7a8c;
  z-index: 5;
  /* Adjust the z-index as needed */
}

.popup {
  box-align: center;
  position: fixed;
  top: 30px;
  left: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 30%;
  z-index: 5;
  /* Adjust the z-index as needed */
}

.popup__content {
  width: 80%;
  /* background-color: white; */
  padding: 0px;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 999;
  /* Adjust the z-index as needed */
}

@media only screen and (max-width: 767px) {
  .popup {
    width: 87%;
    margin-left: 5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .popup {
    width: 75%;
    margin-left: 13%;
  }
}
