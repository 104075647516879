.selectCustom{
    border-radius: 10px;
    color: #fff;
    background-color: #6bd098;
    height: 24px;
    border: none;
}
.selectCustomOption{
    background-color: #fff;
    color: black;
    border-radius: 10px;
}