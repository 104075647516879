.row{
    background-image: url();
}


.body{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    /* margin-top: 5%; */
}



.wrapper {
    position: relative;
    width: 700px;
    height: 75%;
    top: 0;
}

/* .form-wrapper {
    position: absolute;
    top: -8px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #ffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
} */

.form-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100%;
    background: #ffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

h2 {
    font-size: 30px;
    color: #555;
    text-align: center;
}

.input-group {
    position: relative;
    width: 70%;
    margin: 30px 0;
    margin-left: 15%;
   
}

.input-group label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #333;
    padding: 0 5px;
    pointer-events: none;
    transition: .5s;
}

.input-group input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: #333;
    padding: 0 10px;
    background: transparent;
    border: 1px solid #333;
    outline: none;
    border-radius: 5px;
}

.input-group input:focus~label,
.input-group input:valid~label {
    top: 0;
    font-size: 12px;
    background: #fff
}

.btnlogin {
    position: relative;
    top: 0;
    left: 15%;
    width: 70%;
    height: 40px;
    background-color: #000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    font-size: 18px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease; /* Add transition for a smooth effect */
}

.btnlogin:hover {
    background-color:#cacbce;
    color: #000;
}


.btnlogin2 {
    position: relative;
    top: 0;
    left: 15%;
    width: 70%;
    height: 40px;
    background-color: #a3a3a3;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
    font-size: 18px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease; /* Add transition for a smooth effect */
}

.btnlogin2:hover {
    background-color:#000;
    color: #fff;
}

@media (max-width: 680px) {
    .hide-on-small {
      display: none;
    }
  }
  